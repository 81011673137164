import type { VideoProps } from 'types/videoTypes';
import { WidgetVideoWithRelated } from '../../widget-video/widget-video-with-related/widget-video-with-related';

export type WidgetArticleDetailVideoProps = {
  readonly itemGroups: ReadonlyArray<{
    readonly items: ReadonlyArray<{
      readonly model: VideoProps;
    }>;
  }>;
};

export default function WidgetArticleDetailVideo({ context }: { context: WidgetArticleDetailVideoProps }) {
  const model = {
    ...context.itemGroups[0].items[0].model,
    related: context.itemGroups[1]?.items.map((item) => item.model),
  };

  return <WidgetVideoWithRelated context={model} />;
}
